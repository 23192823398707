<template>
  <div id="main_div">
    <div id="form_and_chart">
      <div id="form_div" class="center borderStyle">
        <form>
          <div class="temperature">
            <label for="name">Temperature °C</label>
            <input @input="updateChartandDewPoint" type="number" id="name" v-model="temperature" min="-20" max="100"
              autofocus />
          </div>
          <div class="humidity">
            <label for="profileSummary">Relative humidity %</label>
            <input @input="updateChartandDewPoint" type="number" id="profileSummary" v-model="humidity" min="1"
              max="100" />
          </div>
          <div class="humidity">
            <label for="profileSummary">Dew point °C</label>
            <input type="text" id="profileSummary" :value=dew_point readonly/>
          </div>
          <div class="humidity">
            <label for="profileSummary">Absolute humidity g/m3</label>
            <input type="text" id="profileSummary" :value=absolute_humidity readonly/>
          </div>
        </form>
        <p v-if="humidity > 0 && humidity < 101"></p>
        <p v-if="humidity <= 0">Humidity must be greater than 0</p>
        <p v-if="humidity > 100">Humidity must not be greater than 100</p>
      </div>
      <div class="main_chart borderStyle" >
        <VueApexCharts width="100%" height="100%" type="line" :options="chart_options" :series="series"></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import config from "./myconfig";
export default {
  name: "App",
  components: {
    VueApexCharts,
  },
  data() {
    return {
      absolute_humidity: 0,
      dew_point: 0,
      temperature: 20,
      humidity: 50,
      chart_options: {
        chart: {
          id: "vuechart-example",
        },
        title: {
    text: 'Humidity calculator',
    align: 'left',
    margin: 10,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize:  '24px',
      fontWeight:  'bold',
      fontFamily:  undefined,
      color:  '#263238'
    },
},
        stroke: {
          curve: "straight",
        },
        yaxis: {
          title: {
            text: 'Relative humidity (%)',
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(1);     // converts a number to a string, rounded to a specified number of decimals
            }
          },
        },
        xaxis: {
          title: {
            text: 'Temperature (°C)',
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);     // converts a number to a string, rounded to a specified number of decimals
            }
          },
        },
      },
      series: [
        {
          name: "humidity %",
          data: [],
        },
      ],
    };
  },
  created() {
    this.updateChartandDewPoint();
},
  methods: {
    updateChartandDewPoint() {
      this.dew_point = this.dewPoint(this.humidity, this.temperature);
      this.absolute_humidity = this.absoluteHumidity(this.humidity, this.temperature)
      if (this.humidity <= 0 || this.humidity >100){
        this.dew_point = 0
        this.absolute_humidity = 0
        this.series = [
        {
          name: "Rh %",
          data: []
        },
      ];
      return
      }
      let data_points = []
      for (let i = config.MIN_TEMP; i <= config.MAX_TEMP; i++) {
        let rel_humidity = this.relativeHumidity(i, this.dew_point)
        if (rel_humidity >= 100) {
          rel_humidity = 100
        }
        data_points.push([i, rel_humidity])
      }

      this.series = [
        {
          name: "Rh %",
          data: data_points
        },
      ];
    },
    /**
     * Calculates a dew point in Celsius degrees using Magnus coefficients
     * like in https://www.omnicalculator.com/physics/dew-point
     * @param {number} rel_humidity humidity in %
     * @param {number} temperature temperature in degrees of Celsius
     * @return {number} dew point in degrees of Celsius
     */
    dewPoint(rel_humidity, temperature) {
      let a = 17.27;
      let b = 237.3;
      let alpha =
        Math.log(rel_humidity / 100) +
        (a * temperature) / (b + temperature);
      return Math.round(((b * alpha) / (a - alpha)) * 10) / 10;
    },

    /**
     * Calculates relative humidity from dew point and temperature according
     * formula: https://www.calctool.org/atmospheric-thermodynamics/absolute-humidity
     * @param {number} dew_point dew point in degrees of Celsius
     * @param {number} temp temperature in degrees of Celsius
     * @return {number} relative air humidity in %
     */
    relativeHumidity(temp, dew_point){
      let first_part =  Math.exp((17.625 * dew_point) / (243.04 + dew_point));
      let second_part = Math.exp((17.625 * temp) / (243.04 + temp));
      let rel_humidity = 100 *  first_part/second_part;
      return rel_humidity
    },
    /**
     * Calculates absolute air humidity from relative humidity in given temperature
     * More on formula: https://www.omnicalculator.com/physics/absolute-humidity
     * @param {number} Rh_percent relative humidity in percents, i.e. value 0 - 100
     * @param {number} temp_celsius temperature in Celsius degree
     * @return {number} absolute humidity in g/m3 - how many kg of water is in m3 of air
     */
    absoluteHumidity(Rh_percent, temp_celsius){
      let temp_kelvin = temp_celsius + 273.15;
      let abs_humidity_kg_m3 = (Rh_percent * this.saturation_water_vapour_pressure(temp_celsius))/(461.52 * temp_kelvin * 100);
      let abs_humidity_g_m3 = abs_humidity_kg_m3 * 1000
      return Math.round(abs_humidity_g_m3 * 10) / 10;
    },
    /**
     * Calculates water vapour pressure for given temperature using Teten's equation.
     * More on formula: https://en.wikipedia.org/wiki/Vapour_pressure_of_water
     * @param  {number}  temperature in Celsius degrees
     * @return {number}  water vapour pressure in Pascals
     */
    saturation_water_vapour_pressure(temp_celsius){
      let result_Pa = 0
      result_Pa = 610.78 * Math.exp((17.27 * temp_celsius)/(temp_celsius+237.3));
      return result_Pa;
    }
  },
};
</script>

<style>
label,
p {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#2c3e50;
}
input {
  display: block;
  width: 180px;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 1.428;
  color: rgb(79, 79, 79);
  border: 1px solid #ccc;
  border-radius: 4px;
}
.center {
  margin: auto;
}
.main_chart {
  float:left;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  flex-grow: 6;
  padding: 10px;
  flex-basis: 600px;
  height: 90vh;
}
#main_div {
  margin: auto;
  width: 100%;
  height: 100%;
}
#form_and_chart {
  display: flex;
  flex-wrap: wrap;
  height: 95vh;
  /* height: 80vh; */
  /* flex-basis: 200px; */
}
#form_div {
  padding: 20px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  flex-grow: 1;  
  flex-basis: 70px;
}

.temperature {
  margin-top: 10px;
}

.humidity {
  margin-top: 10px;
}

.borderStyle {
  border-radius: 8px ;
  border: 2px solid #2c3e50;
  background-color: #ffffff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color:#2c3e50;
  margin-top: 0px;
  height:100%;
  width:100%;
  min-width:860px; /* min-width assures that the chart is not too squeezed and not readable */
}

body, html {
  height: 100%;
}


</style>